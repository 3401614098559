var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "bg_center" }),
      _c("headbox", {
        attrs: {
          title: "保单详情",
          bgColor: "#39394D",
          customPath: true,
          path: "claimedit",
          query: { active: "b" },
          isborder: "true"
        }
      }),
      _c("div", { staticClass: "centerbox" }, [
        _c("div", { staticClass: "white step_1 rel" }, [
          _c("h1", [_vm._v(_vm._s(_vm.listdata.companyName))]),
          _c("p", [_vm._v("no." + _vm._s(_vm.listdata.expressNo))]),
          _vm._m(0),
          _c(
            "div",
            { staticClass: "btn_see" },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "claimExplanation" } } },
                [_vm._v("理赔说明")]
              )
            ],
            1
          ),
          _c("div", { staticClass: "isovernow" }, [
            _vm.listdata.status == 1
              ? _c("img", {
                  attrs: {
                    src: require("@/assets/images/overgame.png"),
                    alt: ""
                  }
                })
              : _vm._e(),
            _vm.listdata.status == 0
              ? _c("img", {
                  attrs: {
                    src: require("@/assets/images/baozhangzhong.png"),
                    alt: ""
                  }
                })
              : _vm._e(),
            _vm.listdata.status == 2
              ? _c("img", {
                  attrs: {
                    src: require("@/assets/images/daishengxiao.png"),
                    alt: ""
                  }
                })
              : _vm._e()
          ])
        ]),
        _c("div", { staticClass: "white step_2" }, [
          _c("div", { staticClass: "h1" }, [_vm._v("保障内容")]),
          _c("div", { staticClass: "flex-between" }, [
            _c("p", [_vm._v("被保险人")]),
            _c("p", [_vm._v(_vm._s(_vm.listdata.name))])
          ]),
          _c("div", { staticClass: "flex-between" }, [
            _c("p", [_vm._v("保障期限")]),
            _c("p", [_vm._v(_vm._s(_vm.listdata.insuranceDuration))])
          ]),
          _c("div", { staticClass: "flex-between" }, [
            _c("p", [_vm._v("投保额度")]),
            _c("p", [_vm._v(_vm._s(_vm.listdata.insuranceMoney) + "元")])
          ]),
          _c("div", { staticClass: "h1 mart" }, [_vm._v("基本信息")]),
          _c("div", { staticClass: "flex-between" }, [
            _c("p", [_vm._v("投保人")]),
            _c("p", [_vm._v(_vm._s(_vm.listdata.insuranceBuyName))])
          ]),
          _c("div", { staticClass: "flex-between" }, [
            _c("p", [_vm._v("投保时间")]),
            _c("p", [_vm._v(_vm._s(_vm.listdata.date))])
          ]),
          _c("div", { staticClass: "flex-between" }, [
            _c("p", [_vm._v("顺丰单号")]),
            _c("p", [_vm._v(_vm._s(_vm.listdata.expressNo))])
          ]),
          _c("div", { staticClass: "flex-between" }, [
            _c("p", [_vm._v("运输路线")]),
            _c("p", [_vm._v(_vm._s(_vm.listdata.insurancePath))])
          ]),
          _c("div", { staticClass: "flex-between" }, [
            _c("p", [_vm._v("货品种类")]),
            _c("p", [_vm._v(_vm._s(_vm.listdata.goodName))])
          ]),
          _c("div", { staticClass: "flex-between" }, [
            _c("p", [_vm._v("货品重量")]),
            _c("p", [_vm._v(_vm._s(_vm.listdata.weight) + "g")])
          ]),
          _c("div", { staticClass: "flex-between" }, [
            _c("p", [_vm._v("保险费")]),
            _c("p", [_vm._v(_vm._s(_vm.listdata.insuranceFee) + "元")])
          ])
        ]),
        _c("div", { staticClass: "white seerule" }, [
          _vm._v(" 查看"),
          _c(
            "b",
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "InsuranceClause",
                      query: { type: "claimDetails" }
                    }
                  }
                },
                [_vm._v("《货运基本条例》")]
              )
            ],
            1
          ),
          _c(
            "b",
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "claimeditHangkongxian",
                      query: { type: "claimDetails" }
                    }
                  }
                },
                [_vm._v("《附加航空险》")]
              )
            ],
            1
          ),
          _vm._v("和"),
          _c(
            "b",
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "claimeditdelivery",
                      query: { type: "claimDetails" }
                    }
                  }
                },
                [_vm._v("《附加邮包险》")]
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("img", {
        staticClass: "imgbox",
        attrs: { src: require("@/assets/images/computericon.png") }
      }),
      _vm._v("由中国太平洋保险有限公司承保 ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }